import {
	PUSH_RADIOS,
	SET_CURRENT_RADIO,
	SET_FEATURED_RADIOS,
	SET_RADIO,
	SET_RADIOS,
	SET_SAME_PARENT_RADIOS,
	SET_CURRENT_RADIOS_STREAMS,
	SET_FEEDS_PODCASTS,
	SET_EPISODES_PODCASTS,
} from '../actions/constants';

const defaultState = {
	emissions: [
		{
			id: '1',

			startHour: '8h00',
			endHour: '9h00',
			title: 'Oldies & Goldies',
			caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
		},
		{
			id: '2',
			startHour: '8h00',
			endHour: '9h00',
			title: 'Oldies & Goldies',
			caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
		},
		{
			id: '3',
			startHour: '8h00',
			endHour: '9h00',
			title: 'Oldies & Goldies',
			caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
		},
	],
	days: [
		{ name: 'Lundi' },
		{ name: 'Mardi' },
		{ name: 'Mercredi' },
		{ name: 'Jeudi' },
		{ name: 'Vendredi' },
		{ name: 'Samedi' },
		{ name: 'Dimanche' },
	],
	currentRadio: {},
	sameParentRadios: [],
	radios: [],
	featured: [],
	streams: [],
	radioPodcastsFeeds: null,
	radioPodcastsEpisodes: null,
};

export default function radio(state = defaultState, action) {
	switch (action.type) {
		case SET_FEATURED_RADIOS:
			return { ...state, featured: action?.payload };
		case SET_RADIOS:
			return { ...state, radios: action?.payload };
		case SET_RADIO:
			return { ...state, ...action?.payload };
		case SET_CURRENT_RADIO:
			return { ...state, currentRadio: action?.payload };
		case SET_SAME_PARENT_RADIOS:
			return { ...state, sameParentRadios: action?.payload };
		case PUSH_RADIOS:
			return {
				...state,
				radios: action?.payload?.list,
			};
		case SET_CURRENT_RADIOS_STREAMS:
			return { ...state, streams: action?.payload };
		case SET_FEEDS_PODCASTS:
			return { ...state, radioPodcastsFeeds: action?.payload };
		case SET_EPISODES_PODCASTS: {
			return { ...state, radioPodcastsEpisodes: action?.payload };
		}
		default:
			return state;
	}
}

import React, { useState } from 'react';
import './style.css';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import { AiFillAppstore } from 'react-icons/ai';

const RadioItemGroup = ({ item, page, categorieID, imageUrl }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);

	const handleOnClick = (e) => {
		if (e.target.localName === 'div') {
			navigate(`/categorie/${categorieID}`);
		}
	};
	const handleOnPlay = () => {
		navigate(`/categorie/${categorieID}`);
	};

	return (
		<div
			id={`radio${item?.rpID}`}
			name="redirectButton"
			onClick={(e) => handleOnClick(e)}
			className={`radioItemCon page-${page}`}
			style={{ backgroundImage: `url(${imageUrl})` }}
		>
			<img alt={item.nom} src={imageUrl} onLoad={() => setLoading(false)} onError={() => setInvalid(true)} />
			<Loading
				size={30}
				color="red"
				conClass="radioItemLoading"
				style={{ opacity: loading && !invalid ? 1 : 0 }}
			/>
			<Button variant="light" onClick={handleOnPlay}>
				<AiFillAppstore color="#000" size={15} />
			</Button>
		</div>
	);
};

export default RadioItemGroup;

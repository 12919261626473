import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
	Home,
	About,
	Single,
	Listing,
	Podcasts,
	PodcastsSeries,
	PodcastsCategories,
	Feed,
	Article,
	Contact,
	Devices,
	Alexa,
	Cgu,
	Mentions,
	Policy,
	Google,
} from '../pages';

const Navigator = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/radios" element={<Listing />} />
			<Route path="/podcasts" element={<Podcasts />} />
			<Route path="/podcast/:idseries" element={<PodcastsSeries />} />
			<Route path="/podcasts-category/:catName" element={<PodcastsCategories />} />
			<Route path="/categorie/:catName" element={<Listing />} />
			<Route path="/radio/:rpID" element={<Single />} />
			<Route path="/articles" element={<Feed />} />
			<Route path="/article/:id" element={<Article />} />
			<Route path="/radioplayer-pour-amazon-alexa" element={<Alexa />} />
			<Route path="/radioplayer-pour-google-assistant" element={<Google />} />
			<Route path="/cgu" element={<Cgu />} />
			<Route path="/mentions" element={<Mentions />} />
			<Route path="/policy" element={<Policy />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/a-propos" element={<About />} />
			<Route path="/ecouter-radioplayer" element={<Devices />} />
		</Routes>
	);
};

export default Navigator;

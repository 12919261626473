import React, { useEffect, useState } from 'react';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { scrollTop } from '../../helpers/behavior';
import { ShareModal } from '../../components';
import { TiMediaPause } from 'react-icons/ti';
import { SET_PLAYER } from '../../store/actions/constants';
import { IoPlay } from 'react-icons/io5';
import { Helmet } from 'react-helmet';
import { site_url } from '../../config/keys';
import { getPodcastsSeries, getCurrentPodcast } from '../../store/actions/podcasts';
import { getRadioById } from '../../store/actions/radio';
import { Episodes } from './childs';
import translate from '../../translation';
import { setPlayer } from '../../store/actions/player';
import Loading from '../../components/Loading';
import { SET_SERIES_PODCAST, SET_CURRENT_PODCAST, SET_CURRENT_RADIO } from '../../store/actions/constants';

const { REACT_APP_DEFAULT_PODCAST_IMAGE } = process.env;

export default function PodcastsSeries() {
	const dispatch = useDispatch();
	const { idseries } = useParams();
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);
	const [lastPodcast, setLastPodcast] = useState({});
	const navigate = useNavigate();
	const { podcastsSeries, currentPodcast } = useSelector((state) => state.podcasts);
	const { playing, stream: playedPodcast, isRadio } = useSelector((state) => state.player);
	const { currentRadio } = useSelector((state) => state.radio);
	const [showShare, setShowShare] = useState(false);
	const { innerWidth: width } = window;
	const podcasts_url = `${site_url}/podcast/${idseries}`;

	const onShare = () => {
		if (width >= 768) {
			setShowShare(true);
		} else {
			if (navigator.share) {
				navigator
					.share({
						url: `${site_url}/podcast/${idseries}`,
					})
					.then(() => {})
					.catch(console.error);
			} else {
				setShowShare(true);
			}
		}
	};

	const handleOnPlay = () => {
		const payload = {
			playing: !(playing && playedPodcast?.url === lastPodcast?.audio_url),
			stream: {
				nom: lastPodcast?.mediumName,
				description: lastPodcast?.longName,
				rpID: lastPodcast?.rpID,
				image: lastPodcast?.image,
				stream: lastPodcast?.audio_url,
				url: lastPodcast?.audio_url,
			},
			playedSeconds: 0,
			deltaTime: 0,
			userClick: true,
			isRadio: false,
		};
		dispatch({ type: SET_PLAYER, payload: payload });
		dispatch(setPlayer({ podcastId: idseries }));
	};
	useEffect(() => {
		dispatch({ type: SET_SERIES_PODCAST, payload: [] });
		dispatch({ type: SET_CURRENT_PODCAST, payload: {} });
		dispatch({ type: SET_CURRENT_RADIO, payload: {} });
	}, [dispatch]);

	useEffect(() => {
		if (podcastsSeries) {
			setLastPodcast(podcastsSeries[0]);
		}
	}, [podcastsSeries]);
	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		const asyncProcess = () => {
			dispatch(getPodcastsSeries(idseries, navigate));
			dispatch(getCurrentPodcast(idseries, navigate));
		};
		asyncProcess();
	}, [dispatch, navigate, idseries]);

	useEffect(() => {
		if (currentPodcast && currentPodcast?.rpID) dispatch(getRadioById(currentPodcast?.rpID, navigate));
	}, [dispatch, navigate, currentPodcast]);

	return (
		<div id="podcastsSeries">
			<Helmet>
				<title>{`Ecouter ${lastPodcast?.mediumName} sur Radioplayer - Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={lastPodcast?.description} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
				<meta property="og:title" content={lastPodcast?.mediumName} />
				<meta property="og:description" content={lastPodcast?.description} />
				<meta property="og:image" content={lastPodcast?.image?.url} />
			</Helmet>
			<section className="section1">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="text-center">
							<div
								className="podcastImg"
								style={{
									backgroundImage: `url(${
										!invalid ? currentPodcast?.image : REACT_APP_DEFAULT_PODCAST_IMAGE
									})`,
								}}
								onLoad={() => setLoading(false)}
								onError={() => setInvalid(true)}
							>
								<img
									alt={currentPodcast?.nom}
									src={currentPodcast?.image}
									onLoad={() => setLoading(false)}
									onError={() => setInvalid(true)}
									style={{ display: loading && !invalid ? 'block' : 'none' }}
								/>
								<Loading
									size={30}
									color="red"
									conClass="podcastItemLoading"
									style={{ opacity: loading && !invalid ? 1 : 0 }}
								/>
							</div>
							<div>
								<div>
									<h2>{currentPodcast?.nom}</h2>
									<h5>{currentRadio.nom}</h5>
									<p>{currentPodcast?.description}</p>
								</div>
								<Loading
									size={30}
									color="red"
									conClass="podcastInfosLoading"
									style={{ opacity: loading && !invalid ? 1 : 0 }}
								/>
							</div>
							{(currentRadio.facebook || currentRadio.twitter) && (
								<div className="social">
									{currentRadio.facebook && (
										<Button
											className="facebook"
											href={'https://facebook.com/' + currentRadio.facebook}
											target="_blank"
										/>
									)}
									{currentRadio.twitter && (
										<Button
											className="twitter"
											href={'https://twitter.com/' + currentRadio.twitter}
											target="_blank"
										/>
									)}
								</div>
							)}
							{currentRadio.site && (
								<Button variant="site" href={currentRadio.site} target="_blank">
									{translate('Site officiel')}
								</Button>
							)}
							<Button variant="share" onClick={onShare}>
								{translate('Partager')}
							</Button>
						</Col>
					</Row>
					<Row>
						<Col className="text-center">
							<Button onClick={handleOnPlay} variant="podcasts">
								{playing && playedPodcast.url === lastPodcast?.audio_url && !isRadio ? (
									<TiMediaPause color="#000" size={55} />
								) : (
									<div>
										<IoPlay color="#000" size={45} />
									</div>
								)}
							</Button>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="section2 mb-1">
				<Container>
					<h2 className="title mt-3 mb-5">
						{translate("Afficher le dernier épisode d'une émission podcast")}
					</h2>
					<Row>
						<Episodes episodes={podcastsSeries} />
					</Row>
				</Container>
			</section>
			<ShareModal
				//title={translate("ShareTitle")}
				shareUrl={podcasts_url}
				show={showShare}
				onHide={() => setShowShare(false)}
			/>
		</div>
	);
}

import { SET_RADIO_PROGRAM, SET_RADIO_PROGRAM_LOADING } from './constants';
import apiInstance from '../../helpers/apiInstance';

export const getRadioPrograms = (id, params) => {
	return (dispatch) =>
		apiInstance
			.get(`/radios/${id}/programs`, { params })
			.then(({ data }) => {
				if (data.programs) {
					dispatch({
						type: SET_RADIO_PROGRAM,
						payload: data.programs,
					});
				} else {
					dispatch({
						type: SET_RADIO_PROGRAM_LOADING,
						payload: false,
					});
				}
			})
			.catch((error) => {
				dispatch({ type: SET_RADIO_PROGRAM_LOADING, payload: false });
			});
};

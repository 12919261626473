import React from 'react';

const Info = ({ size, color }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size + 2} ${size + 2}`}>
			<g data-name="Layer 2">
				<g data-name="Info-up">
					<rect width={size} height={size} opacity="0" transform="rotate(180 12 12)" />
					<path
						fill={color}
						d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"
					/>
					<circle fill={color} cx="12" cy="8" r="1" />
					<path fill={color} d="M12 10a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1z" />
				</g>
			</g>
		</svg>
	);
};
export default Info;

import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import translate from '../../translation';
import { RadioItem, SearchBar, Loading, Empty } from '../../components';
import { getCategories } from '../../store/actions/category';
import { getRadios } from '../../store/actions/radio';
import { useNavigate, useParams } from 'react-router-dom';
import { scrollToElement } from '../../helpers/behavior';
import { Helmet } from 'react-helmet';
import { Categories, CategoriesHome } from '../Home/childs';

/**
 * Page affichant la liste des différentes radios disponibles
 * @returns JSX
 */
function Listing() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [loading, setLoading] = useState(true);
	const [thisCategorie, setThisCategorie] = useState();
	const { radios } = useSelector((state) => state.radio);
	const { suggestions } = useSelector((state) => state.search);
	const { categories } = useSelector((state) => state.category);
	const { innerWidth: width } = window;

	// Chargement des radios en fonction de la catégorie selectionnée
	useEffect(() => {
		if (params.catName) {
			scrollToElement('#listingPage .section2 .radiosBlockTitle');
		}
		// setPage(0);
		const asyncProcess = async () => {
			setLoading(true);
			const cat = categories.find((category) => category.name === params.catName?.replaceAll('_', ' '))?.id;
			dispatch(getRadios({ cat }));
			setLoading(false);
		};
		asyncProcess();
	}, [params, dispatch, categories]);

	useEffect(() => {
		const currentCategorieShown = categories.find(
			(categorie) => categorie.name.toString() === params.catName?.replaceAll('_', ' ')
		);
		if (currentCategorieShown) setThisCategorie(currentCategorieShown.name);
	}, [params, categories]);

	// Chargement des différentes catégories
	useEffect(() => {
		dispatch(getCategories());
	}, [dispatch]);

	/**
	 * Clic sur le bouton 'toutes les radios' (s'affiche si width < 768)
	 */
	const onClick = () => {
		navigate('/radios');
	};

	return (
		<div id="listingPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section1">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="text-center">
							<h1>{translate('Ecoutez vos radios préférées')}</h1>
							<SearchBar />
							<Button onClick={onClick} className="allRadios" variant="secondary">
								{translate('Toutes les radios')}
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section2" style={{ position: suggestions.length > 0 ? '' : 'relative' }}>
				<Container>
					<h2>{translate('listingCatsTitle')}</h2>
					<div className="catsBlock">
						{width >= 768 ? (
							<CategoriesHome categories={categories} />
						) : (
							<Categories categories={categories} />
						)}
					</div>
					<h2 className="radiosBlockTitle">
						{translate('listingRadiosTitle')} - {thisCategorie}
					</h2>
					<div className="radiosBlock">
						{(() => {
							if (loading) {
								return <Loading color="#cf2345" size={50} conClass="loading" />;
							} else {
								if (radios.length === 0) {
									return <Empty text={translate('Aucune radio disponible')} />;
								} else {
									return radios.map((item, index) => (
										<RadioItem isListing={true} key={index?.toString()} item={item} />
									));
								}
							}
						})()}
					</div>
				</Container>
			</section>
		</div>
	);
}

export default Listing;

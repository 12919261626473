import { SET_RADIO_PROGRAM, SET_RADIO_PROGRAM_LOADING } from '../actions/constants';

const defaultState = {
	programs: [],
	emissions: [],
	loading: true,
};

export default function program(state = defaultState, action) {
	switch (action.type) {
		case SET_RADIO_PROGRAM:
			return { ...state, emissions: action?.payload, loading: false };
		case SET_RADIO_PROGRAM_LOADING:
			return { ...state, loading: action?.payload };

		default:
			return state;
	}
}

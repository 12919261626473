import React, { useState, useEffect } from 'react';
import './style.css';
import ReactPlayer from 'react-player';
import { Volume, Pulse, Seek } from './childs';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayer } from '../../store/actions/player';
import { Button } from 'react-bootstrap';
import { InfoIcon, PlayPauseIcon, ShareIcon } from '../../assets/icons';
import Loading from '../Loading';
import { SET_PLAYER } from '../../store/actions/constants';
import { useNavigate } from 'react-router-dom';
import { ShareModal } from '..';
import { site_url } from '../../config/keys';
import { getBestStream } from '../../helpers/radio';
import { getStreamsRadioById } from '../../store/actions/radio';
import apiInstance from '../../helpers/apiInstance';
import { IoPlayForwardSharp, IoPlayBackSharp } from 'react-icons/io5';
import { useInterval } from '../customHook';

// FIREBASE
import { getApps, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
	apiKey: 'AIzaSyDE13iQWuFu4Uz6Di9cwhXX1H7DOJNH0xE',
	authDomain: 'radioplayer-fr.firebaseapp.com',
	projectId: 'radioplayer-fr',
	storageBucket: 'radioplayer-fr.appspot.com',
	messagingSenderId: '401175634106',
	appId: '1:401175634106:web:2435c87fae06e88438e283',
	measurementId: 'G-J9YTY1FEBD',
};

const playerRef = React.createRef();

const Player = () => {
	var firebase_app = null;
	if (!getApps().length) firebase_app = initializeApp(firebaseConfig);
	else {
		firebase_app = getApps[0];
	}
	const analytics = getAnalytics(firebase_app);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		stream,
		volume,
		muted,
		playing,
		played,
		seeking,
		playedSeconds,
		isRadio,
		podcastId,
		pub,
		radioStatePlay,
		deltaTime,
		lastRpidPlayed,
		lastStartTime,
		userClick,
	} = useSelector((state) => state.player);
	const numberOfStreamsListControl = 5;
	const [showShare, setShowShare] = useState(false);
	const { innerWidth: width } = window;
	const maxCharacters = width > 1200 ? 60 : 26;
	const [lastRadioMeta, setCurrentRadioMeta] = useState(null);
	const [duration, setDuration] = useState(0);
	const { streams } = useSelector((state) => state.radio);
	const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
	const [currentUrl, setCurrentUrl] = useState();
	const [streamsBrowseCount, setStreamsBrowseCount] = useState(0);

	// custom hook pour faire un appel périodique
	useInterval(
		() => {
			fetchRadioMeta();
		},
		isRadio && playing ? 10000 : null
	);
	function fetchRadioMeta() {
		apiInstance
			.get(`/radios/${stream?.rpID}/meta`)
			.then(({ data }) => {
				setCurrentRadioMeta(data);
			})
			.catch((error) => {});
	}

	const radioListeningState = {
		Unstarted: 0,
		FirstPub: 1,
		PauseFirstPub: 2,
		NoPub: 3,
		PauseNoPub: 4,
		AfterNoPub: 5,
	};

	window.onbeforeunload = closingCode;

	const rewindThirtySeconds = () => {
		playerRef.current.seekTo(playerRef.current.getCurrentTime() - 30);
	};

	const forwardThirtySeconds = () => {
		playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30);
	};

	const formatPlayedTime = (secs) => {
		const minutes = Math.floor(secs / 60);
		const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(secs % 60);
		const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${returnedMinutes}:${returnedSeconds}`;
	};
	useEffect(() => {
		if (isRadio) {
			setCurrentUrl(streams[currentUrlIndex]?.url);
		} else {
			setCurrentUrl(stream?.url);
		}
	}, [streams, isRadio, stream, currentUrlIndex]);

	/**
	 * Construit la description de la radio en cours de lecture (description de la radio par défault ou titre en cours si dispo)
	 * @returns {string} La description construite
	 */
	function getRadioDescription() {
		let result = '';
		if (lastRadioMeta?.onair?.[0]?.name && isRadio) {
			const role = lastRadioMeta.onair[0].mediaCredits?.[0]?.role;
			if (role === 'artist') {
				const artist = lastRadioMeta.onair[0].mediaCredits?.[0]?.value;
				result = `${lastRadioMeta.onair[0].name} - ${artist}`;
			} else {
				result = `${lastRadioMeta.onair[0].name}`;
			}
		} else if (!isRadio) {
			result = `${stream?.description?.substr(0, maxCharacters)}`;
			if (stream?.description?.length > maxCharacters) {
				result += '...';
			}
		} else {
			result = `${stream?.description?.substr(0, maxCharacters)}`;
			if (stream?.description?.length > maxCharacters) {
				result += '...';
			}
		}
		return result;
	}

	const handleLoadMetadata = (meta) => {
		const { duration } = meta.target;
		setDuration(formatPlayedTime(duration));
	};

	/**
	 * Mets à jour le state du player
	 * @param {*} state
	 */
	const handleProgress = (state) => {
		if (!seeking) {
			if (isRadio && playing) updatePubState();
			dispatch(setPlayer(state));
		}
	};

	/**
	 * Mets à jour la variable showShare
	 */
	const onShare = () => {
		if (width >= 768) {
			setShowShare(true);
		} else {
			if (navigator.share) {
				navigator
					.share({
						url: `${site_url}/radio/${stream?.rpID}-${stream?.nom?.trim()?.replace(/ /g, '-')}`,
					})
					.then(() => {})
					.catch(console.error);
			} else {
				setShowShare(true);
			}
		}
	};

	const updatePubState = () => {
		const thirtySeconds = 30 * 1000; // in millis
		const fifteenMinutes = 15 * 60 * 1000; // in millis
		const pubState = {
			PlayPub: 0,
			NoPub: 1,
		};

		const delta = deltaTime + new Date().getTime() - lastStartTime;
		const isNoPubState = delta > thirtySeconds && delta < fifteenMinutes;

		let playerState = {
			pub: isNoPubState ? pubState.NoPub : pubState.PlayPub,
			radioStatePlay:
				delta < thirtySeconds
					? playing
						? radioListeningState.FirstPub
						: radioListeningState.PauseFirstPub
					: delta > fifteenMinutes
						? radioListeningState.AfterNoPub
						: playing
							? radioListeningState.NoPub
							: radioListeningState.PauseNoPub,
		};

		dispatch(setPlayer(playerState));
	};

	const startMetaRecording = () => {
		if (userClick) {
			localStorage.setItem('lastRadioListened', stream?.rpID);
			if (document.querySelector('.playerContainer').style.bottom !== '0px')
				document.querySelector('.playerContainer').style.bottom = '0px';
			if (lastRpidPlayed && lastRpidPlayed !== stream?.rpID) {
				sendListeningDurationAnalytics();
			}
			sendPlayEventAnalytics();

			let playerState = {
				lastStartTime: new Date().getTime(),
				lastRpidPlayed: isRadio ? stream?.rpID : null,
				deltaTime:
					(isRadio &&
						lastRpidPlayed !== stream?.rpID &&
						!(
							radioStatePlay === radioListeningState.NoPub ||
							radioStatePlay === radioListeningState.PauseNoPub
						)) ||
					!isRadio
						? 0
						: deltaTime + new Date().getTime() - lastStartTime,
				userClick: false,
			};

			dispatch(setPlayer(playerState));

			if (isRadio) {
				updatePubState();
				fetchRadioMeta();
			}
		}
	};

	const stopMetaRecording = () => {
		if (userClick) {
			sendListeningDurationAnalytics();
			if (isRadio) {
				let playerState = {
					deltaTime: deltaTime + new Date().getTime() - lastStartTime,
					userClick: false,
				};
				if (radioStatePlay === radioListeningState.FirstPub)
					playerState.radioStatePlay = radioListeningState.PauseFirstPub;
				if (radioStatePlay === radioListeningState.NoPub)
					playerState.radioStatePlay = radioListeningState.PauseNoPub;
				dispatch(setPlayer(playerState));
			}
		}
	};

	const togglePlaying = async () => {
		dispatch(setPlayer({ playing: !playing, userClick: true }));
		isRadio && dispatch(await getStreamsRadioById(stream?.rpID, navigate));
	};

	const handleUrlError = async (err) => {
		if (streamsBrowseCount <= numberOfStreamsListControl) {
			dispatch({
				type: SET_PLAYER,
				payload: { playing: false },
			});
			if (currentUrlIndex < streams.length - 1) {
				setCurrentUrlIndex(currentUrlIndex + 1);
			} else {
				setCurrentUrlIndex(0);
				setStreamsBrowseCount(streamsBrowseCount + 1);
			}
		} else if (streamsBrowseCount > numberOfStreamsListControl) {
			navigate(0);
			return;
		}
	};

	const switchToLowerStreamQuality = async () => {
		const url = getBestStream(streams, true, streams[0]);
		if (!url) {
			dispatch(await getStreamsRadioById(stream?.rpID, navigate));
			dispatch({
				type: SET_PLAYER,
				payload: { playing: false },
			});
			dispatch({
				type: SET_PLAYER,
				payload: { playing: true },
			});
		} else {
			dispatch({
				type: SET_PLAYER,
				payload: { stream: { ...stream, url } },
			});
		}
	};

	/**
	 * Ajoute d'un paramètre à l'url en fonction de la dernière date de lecture
	 * @param {*} url
	 * @returns {string} l'url avec en la nouvelle variable en paramètre
	 */
	const getStreamUrl = (url) => {
		if (!url) return url;
		// if url have some params
		if (url.indexOf('?') >= 0) {
			const urlParts = url.split('?');
			// if url have some other params
			if (url.indexOf('&') >= 0) {
				urlParts[1] = urlParts[1]
					.split('&')
					.filter((keyValue) => !keyValue.includes('aw_0_1st.rpfr'))
					.join('&');
				return urlParts.join('?') + `&aw_0_1st.rpfr=${pub}`;
			} else {
				return urlParts[1].includes('aw_0_1st.rpfr')
					? urlParts[0] + `?aw_0_1st.rpfr=${pub}`
					: urlParts.join('?') + `&aw_0_1st.rpfr=${pub}`;
			}
		} else {
			const parameterSymbol = '?';
			return `${url}${parameterSymbol}aw_0_1st.rpfr=${pub}`;
		}
	};
	/**
	 * Redirige l'utilisateur vers la page de la radio en cours de lecture
	 * @param {*} e
	 */
	const onClickInfo = (e) => {
		isRadio
			? navigate(`/radio/${stream?.rpID}-${stream?.nom?.trim()?.replace(/ /g, '-')}`)
			: navigate(`/podcast/${podcastId}`);
	};

	/**
	 * Envoie d'un event analytics pour le début de lecture
	 */
	const sendPlayEventAnalytics = async () => {
		const radio_rpid = stream?.rpID;
		await logEvent(analytics, 'rp_web_player_play', {
			radio_id: radio_rpid,
		});
	};

	/**
	 * Envoie d'un event analytics pour l'arret de la lecture
	 */
	const sendListeningDurationAnalytics = async () => {
		if (lastStartTime > 0) {
			const radio_rpid = stream?.rpID;
			const duration = (new Date().getTime() - lastStartTime) / 1000;
			await logEvent(analytics, 'rp_web_player_stop', {
				radio_id: radio_rpid,
				listen_duration: duration,
			});
		}
	};

	/**
	 * envoie de l'event lorsque l'utilisateur ferme l'onglet
	 * @returns {null}
	 */
	function closingCode() {
		if (playing) {
			sendListeningDurationAnalytics();
		}
		return null;
	}

	return (
		<div className="playerContainer">
			<div>
				<ReactPlayer
					onPlay={() => {
						startMetaRecording();
					}}
					onPause={() => {
						stopMetaRecording();
					}}
					onProgress={handleProgress}
					onError={() => {
						switchToLowerStreamQuality();
						handleUrlError();
					}}
					onLoadedMetadata={handleLoadMetadata}
					playing={playing}
					played={played}
					volume={muted ? 0 : volume}
					width="0"
					height="0"
					ref={playerRef}
					url={isRadio && playing ? getStreamUrl(currentUrl) : getStreamUrl(currentUrl)}
				/>
				<div className="player">
					<div className="image" style={{ backgroundImage: `url(${stream?.image})` }} />
					<div className="controls d-flex">
						<div className="d-flex align-items-md-center ">
							<div className="d-flex flex-column align-items-center">
								<div className="d-flex align-items-center">
									{!isRadio ? (
										<div className="d-flex">
											<button className="forwardBackward" onClick={rewindThirtySeconds}>
												<IoPlayBackSharp />
												<span>-30</span>
											</button>
										</div>
									) : null}
									<Button onClick={togglePlaying} variant="primary" className="optionBtn">
										{(playedSeconds > 0 || playing === false) && (
											<PlayPauseIcon size={28} color="#fff" playing={playing} />
										)}
										{playedSeconds === 0 && playing === true && <Loading size={16} color="#fff" />}
									</Button>
									{!isRadio ? (
										<div className="d-flex">
											<button className="forwardBackward" onClick={forwardThirtySeconds}>
												<span>+30</span>
												<IoPlayForwardSharp />{' '}
											</button>
										</div>
									) : null}
								</div>
								{!isRadio ? (
									<div className="playerTime">
										{formatPlayedTime(playedSeconds)} / {duration}
									</div>
								) : null}
							</div>
							<div className="ms-5 ms-md-3 ms-lg-5">
								{!isRadio ? (
									<div className="seekBar mb-3">
										<Seek playerRef={playerRef} duration={duration} />
									</div>
								) : null}
								<div className="d-flex align-items-md-center minHeightPulse">
									<span className="name">{isRadio ? stream?.nom : stream?.nom}</span>
									{playedSeconds > 0 && playing === true && <Pulse />}
								</div>
								<span className="title">{getRadioDescription()}</span>
							</div>
						</div>
					</div>
					<div className="options">
						<Volume />
						<div>
							<Button variant="dark" onClick={onClickInfo} className="optionBtn ">
								<InfoIcon size={23} color="#fff" />
							</Button>
							<Button variant="dark" onClick={onShare} className="optionBtn ">
								<ShareIcon size={22} color="#fff" />
							</Button>
						</div>
					</div>
				</div>
				{!isRadio ? (
					<ShareModal
						shareUrl={`${site_url}/podcast/${podcastId}`}
						show={showShare}
						onHide={() => setShowShare(false)}
					/>
				) : (
					<ShareModal
						shareUrl={`${site_url}/radio/${stream?.rpID}-${stream?.nom?.trim()?.replace(/ /g, '-')}`}
						show={showShare}
						onHide={() => setShowShare(false)}
					/>
				)}
			</div>
		</div>
	);
};
export default Player;

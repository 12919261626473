import { SET_CURRENT_FEED, SET_FEEDS } from './constants';

import apiInstance from '../../helpers/apiInstance';

export const getArticles = (page = 0, count = 6) => {
	const params = { count: count, page: page };
	return (dispatch) =>
		apiInstance
			.get('/articles', { params })
			.then(async ({ data }) => {
				if (data) {
					const list = await Promise.all(data.items);
					if (list) {
						dispatch({
							type: SET_FEEDS,
							payload: {
								page: page,
								list,
								isLastPage: data.meta.pagesCount <= page + 1,
							},
						});
					}
				}
			})
			.catch((error) => {});
};

export const getArticleById = (id) => {
	return (dispatch) =>
		apiInstance
			.get(`/articles/${id}`)
			.then(async ({ data }) => {
				if (data) {
					dispatch({ type: SET_CURRENT_FEED, payload: data.item });
				}
			})
			.catch((error) => {});
};
